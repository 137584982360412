<template>
  <v-card
    class="info-card"
    elevation="24"
    color="#f6f4f5"
  >
    <v-row align="center">
      <v-col sm="5" class="mb-16 pr-n16">
        <v-img
          src="../assets/Logos/LogoMalis.svg"
          contain
          transition="scale-transition"
          height="300"
        />
      </v-col>

      <v-col sm="7">
        <v-card-text id="parrafos">
          <p>
          The MaLIS-Lab of the Department of Computer Engineering (DIINF) at USACH researches the intersection of image and signal processing, machine learning, and its applications to contemporary problems in diverse fields such as mining, neuroscience, and medical diagnosis support. The transversal feature of our vast and varied lines of research is the analysis of image and signal data.
          </p>

          <p>
          Our research focuses on designing brain-computer interfaces for assistive technologies and human-machine interaction, image segmentation for basic neuroscience research, image processing and segmentation, and automatic classification for male infertility and breast cancer diagnosis, sensor software design, and neural modeling biotechnological and mining and manufacturing applications.
          </p>

          <p>
          Our lab's research focuses on advancing the state-of-the-art machine learning area with a strong focus on technology transfer, looking forward to contributing to our society's development. In this sense, the team of researchers, assistants, and students focus on domain adaptation to take advantage of current state-of-the-art methods applied to data and situations of the national reality.
          </p>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  name: 'Malis',
}

</script>

<style scoped>
  #parrafos {
    text-align: justify;
    text-size-adjust: auto;
    align: center;
  }
  .info-card{
    margin:64px auto 132px;
    width:75%;
    opacity: 0;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @keyframes slide-top {
    0% {
      opacity: 0;
      transform: translateY(1000px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
</style>